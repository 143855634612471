export var DEVICE_SIZES;
(function (DEVICE_SIZES) {
    DEVICE_SIZES["EXTRA_SMALL_DEVICE"] = "extrasmall";
    DEVICE_SIZES["SMALL_DEVICE"] = "small";
    DEVICE_SIZES["MEDIUM_DEVICE"] = "medium";
    DEVICE_SIZES["LARGE_DEVICE"] = "large";
    DEVICE_SIZES["EXTRA_LARGE_DEVICE"] = "extralarge";
    // Device shorthands
    DEVICE_SIZES["XS"] = "extrasmall";
    DEVICE_SIZES["SM"] = "small";
    DEVICE_SIZES["MD"] = "medium";
    DEVICE_SIZES["LG"] = "large";
    DEVICE_SIZES["XL"] = "extralarge";
})(DEVICE_SIZES || (DEVICE_SIZES = {}));
