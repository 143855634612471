import * as React from 'react';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
// Will return true on the client and false otherwise
const useIsClient = () => {
    // Will set ready to false on web and true on mobile as mobile does not have SSR
    const [isClient, setIsClient] = useState(Platform.OS !== 'web');
    // Use effect will only run on the client, so we can use that to signal we have reached the client
    useEffect(() => {
        setIsClient(true);
    }, []);
    return isClient;
};
export function SSRProvider({ placeholder = null, children }) {
    const isClient = useIsClient();
    if (!isClient)
        return React.createElement(React.Fragment, null, placeholder);
    return React.createElement(React.Fragment, null, children);
}
